import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, DatePicker, Divider, Form, Image, Input, InputNumber, Row, Select, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import companyApi from '../../../apis/CompanyApi';
import trapImageApi from '../../../apis/TrapImageApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { Company, TrapImage } from '../../../models/Entities';
import { crops, farmings, plagues } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import stringService from '../../../services/StringService';
import styles from './AdminTrapImagePage.module.scss';

/**
 * Returns the admin trap image page.
 * @returns the admin trap image page.
 */
const AdminTrapImagePage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const { modal } = App.useApp();
    const params = useParams<ParamsType>();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [trapImage, setTrapImage] = useState<TrapImage>();
    const [companies, setCompanies] = useState<Company[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');

                // load trap image
                const trapImage = await trapImageApi.get(+params.id!);
                form.setFieldsValue(trapImage);
                setTrapImage(trapImage);

                // load companies
                const companies = await companyApi.list(0, 1000, 'name', true);
                setCompanies(companies.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');
            let updatedTrapImage: TrapImage = Object.assign({}, trapImage, values);
            updatedTrapImage = await trapImageApi.update(updatedTrapImage);
            setTrapImage(updatedTrapImage);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'trapImage.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting', danger: true },
            onOk: async () => {
                try {
                    setLoading('deleting');

                    await trapImageApi.delete(trapImage?.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));

                    navigate('/admin/trap-images');
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const companyOptions = companies
        .sort((a, b) => stringService.sort(a.name, b.name))
        .map((company) => (
            <Select.Option key={company.id} value={company.id}>
                {company.name}
            </Select.Option>
        ));

    const cropOptions = crops.map((c) => (
        <Select.Option key={c} value={c}>
            <FormattedMessage id={`crop.type.${c}`} />
        </Select.Option>
    ));

    const plagueOptions = plagues.map((p) => (
        <Select.Option key={p} value={p}>
            <ConstantLabel prefix="plague.type." value={p} />
        </Select.Option>
    ));
    const farmingOptions = farmings.map((c) => (
        <Select.Option key={c} value={c}>
            <FormattedMessage id={`farming.type.${c}`} />
        </Select.Option>
    ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="trapImages.title" />}
            menu="trapImages"
            path={[
                { path: '/admin/trap-images', name: <FormattedMessage id="trapImages.title" /> },
                { path: `/admin/trap-images/${params.id}`, name: params.id }
            ]}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={4}>
                        <Form.Item
                            label={<FormattedMessage id="trapImage.trapUuid" />}
                            name="trapUuid"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="trapImage.plague" />}
                            name="plague"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select size="large">{plagueOptions}</Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                        <Form.Item label={<FormattedMessage id="trapImage.company" />} name={['company', 'id']}>
                            <Select size="large" showSearch filterOption={stringService.filterOptions} disabled>
                                {companyOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.date" />} name="date">
                            <DatePicker className={styles.date} size="large" showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.temperature" />} name="temperature">
                            <InputNumber suffix="°C" size="large" className={styles.inputNumber} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.humidity" />} name="humidity">
                            <InputNumber suffix="%" size="large" className={styles.inputNumber} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.coordinates.latitude" />} name={['coordinates', 'latitude']}>
                            <InputNumber size="large" className={styles.inputNumber} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.coordinates.longitude" />} name={['coordinates', 'longitude']}>
                            <InputNumber size="large" className={styles.inputNumber} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.batteryVoltage" />} name="batteryVoltage">
                            <InputNumber suffix="V" size="large" className={styles.inputNumber} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={24} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.aemetDate" />} name={['aemetMeasure', 'measurementDate']}>
                            <DatePicker className={styles.date} size="large" format="DD/MM/YYYY" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.aemetTemperature" />} name={['aemetMeasure', 'avgTemp']}>
                            <InputNumber suffix="°C" size="large" className={styles.inputNumber} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.aemetHumidity" />} name={['aemetMeasure', 'avgHumidity']}>
                            <InputNumber suffix="%" size="large" className={styles.inputNumber} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={8} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.insectsCount" />} name="insectsCount">
                            <InputNumber className={styles.inputNumber} size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.crop" />} name="crop">
                            <Select size="large">{cropOptions}</Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.farming" />} name="farming">
                            <Select size="large">{farmingOptions}</Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={4}>
                        <Form.Item label={<FormattedMessage id="trapImage.pheromoneRenewed" />} name="pheromoneRenewed">
                            <DatePicker className={styles.date} size="large" format="DD/MM/YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={16} lg={8} className={styles.buttons}>
                        <Space>
                            <Link to="/admin/trap-images">
                                <Button size="large" icon={<ArrowLeftOutlined />}></Button>
                            </Link>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>

                            {trapImage && trapImage.id && <Button danger size="large" onClick={remove} icon={<DeleteOutlined />}></Button>}
                        </Space>
                    </Col>
                </Row>
                <Divider children={<FormattedMessage id="trapImage.image" />} orientation="left" />
                <Row gutter={[24, 24]}>
                    <Col lg={20}>
                        {trapImage && trapImage.url && (
                            <div>
                                <Image width="100%" src={trapImage.url} />
                            </div>
                        )}
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};
export default AdminTrapImagePage;
type ParamsType = { id: string };
